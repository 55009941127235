<template>
    <div class="publish-device project-apply " v-loading="loading">
        <Header />
        <div class="w-1200">
            <div class="base-step">
                <div class="step-item" :class="{ 'active': true }"><span class="num">1</span><span class="name">注册账号</span></div>
                <i class="divider" :class="{ 'active': true }"></i>
                <div class="step-item" :class="{ 'active': true }"><span class="num">2</span><span class="name">选择身份</span></div>
                <i class="divider" :class="{ 'active': true }"></i>
                <div class="step-item" :class="{ 'active': true }"><span class="num">3</span><span class="name">申请账号身份</span></div>
                <i class="divider"></i>
                <div class="step-item"><span class="num">4</span><span class="name">后台审核</span></div>
            </div>
            <el-form ref="accountRuleForm" :model="accountForm" :rules="accountRules">
                <section class="form">
                    <div class="form-title">申请账号身份</div>
                    <div class="form-main">
                        <div class="form-item">
                            <div class="form-label">供应商申请</div>
                            <div class="form-input-group">
                                <!-- <div class="form-input-item">
                                <div class="form-input-label"><span class="f-red">*</span>公司名称：</div>
                                <el-input v-model="input" placeholder="请输入公司名称…" style="width: 350px;"></el-input>
                            </div> -->
                                <el-form-item label="公司名称：（选填）" prop="company_name">
                                    <el-input v-model="accountForm.company_name" placeholder="请输入公司名称" clearable style="width:400px;"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form-input-group">
                                <el-form-item label="联系人名称：" prop="legal_name">
                                    <el-input v-model="accountForm.legal_name" placeholder="请输入联系人名称" clearable style="width:400px;"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form-input-group">

                                <el-form-item label="联系人身份证号：" prop="id_card">
                                    <el-input v-model="accountForm.id_card" placeholder="请输入联系人身份证号" clearable style="width:400px;"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form-input-group">
                                <el-form-item label="联系人电话：" prop="phone">
                                    <el-input v-model="accountForm.phone" placeholder="请输入联系人电话" clearable style="width:400px;"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <i class="divider"></i>
                        <div class="form-item">
                            <div class="form-label"><span class="f-red">*</span>联系人身份证正面照片</div>
                            <el-upload :limit="1" :file-list="img_files.zm_photo" :on-success="up_success_zm_photo" :on-remove="up_remove_zm_photo" :accept="accept" action="http://114.67.170.224:21880/api/upload" list-type="picture-card">
                                <i class="el-icon-plus"></i>
                                <div>添加上传图片</div>
                            </el-upload>
                        </div>
                        <div class="form-item">
                            <div class="form-label"><span class="f-red">*</span>联系人身份证反面照片</div>
                            <el-upload :limit="1" :file-list="img_files.fm_photo"  :on-success="up_success_fm_photo" :on-remove="up_remove_fm_photo" :accept="accept" action="http://114.67.170.224:21880/api/upload" list-type="picture-card">
                                <i class="el-icon-plus"></i>
                                <div>添加上传图片</div>
                            </el-upload>
                        </div>
                        <div class="form-item">
                            <div class="form-label"><span class="f-red"></span>营业执照（企业需提供，个人可不传）</div>
                            <el-upload :limit="4" :file-list="img_files.business_license" :on-success="up_success_business_license" :on-remove="up_remove_business_license" :accept="accept" action="http://114.67.170.224:21880/api/upload" list-type="picture-card">
                                <i class="el-icon-plus"></i>
                                <div>添加上传图片</div>
                            </el-upload>
                        </div>
                        <!-- <div class="form-item">
                            <div class="form-label"><span class="f-red">*</span>承诺书(下载模板填写完成后盖章扫描成图片)<a target="_blank" class="dow"  href="/承诺书.docx">模板下载</a></div>
                            <el-upload :limit="2" :file-list="img_files.letter_commitment" :on-success="up_success_letter_commitment" :on-remove="up_remove_letter_commitment" :accept="accept" action="http://114.67.170.224:21880/api/upload" list-type="picture-card">
                                <i class="el-icon-plus"></i>
                                <div>添加上传图片</div>
                            </el-upload>
                        </div> -->
                    </div>
                </section>
            </el-form>
            <section class="form-btn-group">
                <span class="btn-item" @click="onregister">立即申请</span>
            </section>
        </div>
        <!-- 申请状态s -->
        <el-dialog title="添加费用" :show-close="false" :visible.sync="applayStatusVisible" width="600px" class="applay-status-dialog">
            <div class="applay-status-main">
                <img :src="require('@/assets/images/icon_success.png')" class="icon-success" alt="">
                <div class="title">申请成功</div>
                <div class="content">供应商资质已经提交申请，需要平台审核通过后<br />发送在线合同链接，在线签订合同后生效</div>
                <!-- <div class="content red">用户注册后默认密码为手机后6位</div> -->
                <section class="form-btn-group">
                    <span class="btn-item btn-border" @click="$router.push({ name: 'home' })">返回首页</span>
                    <span class="btn-item" @click="$router.push({ name: 'personalCenter' })">个人中心</span>
                </section>
            </div>
        </el-dialog>
        <!-- 申请状态e -->
    </div>
</template>

<script>
import kits from "@/kits"
import { setuser_info, register ,get_old_data } from "@/api/user"
import Header from "@/components/header"
export default {
    components: {
        Header
    },
    data() {
        return {
            loading: false,
            old_id: null,
            accept: ".png,.jpg,.jpeg,.bmp",
            applayStatusVisible: false,
            accountForm: {
                company_name: "",
                legal_name: "",
                id_card: "",
                phone: "",
                zm_photo: "",
                fm_photo: "",
                business_license: "",
                letter_commitment:""
            },
            img_arr: {
                zm_photo: [],
                fm_photo: [],
                business_license: [],
                letter_commitment:[]
            },
            img_files: {
                zm_photo: [],
                fm_photo: [],
                business_license: [],letter_commitment:[]
            },
            accountRules: {
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    {
                        pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[0-9])\d{8}$/,
                        message: '请输入正确的手机号码',
                        trigger: 'blur'
                    }
                ],
                company_name: [
                    //{ required: true, message: '长度在 2-50 个字符', trigger: 'blur' },
                    { min: 2, max: 50, message: '长度在 2-50 个字符', trigger: 'blur' }
                ],
                legal_name: [
                    { required: true, message: '长度在 2-50 个字符', trigger: 'blur' },
                    { min: 2, max: 50, message: '长度在 2-50 个字符', trigger: 'blur' }
                ],
                contacts: [
                    { required: true, message: '长度在 2-50 个字符', trigger: 'blur' },
                    { min: 2, max: 50, message: '长度在 2-50 个字符', trigger: 'blur' }
                ],
                id_card: [
                    { required: true, message: '请输入正确的身份证', trigger: 'blur' },
                    {
                        pattern: /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/,
                        message: '请输入正确的身份证',
                        trigger: 'blur'
                    }
                ],
            }
        }
    },
    mounted() {

        if (!this.$route.params.mobile&this.$router.query.r!=1) {
            this.$router.push({ name: 'login', query: { title: '注册' } })
        }
        // 获取数据
        get_old_data({ type: 2 }).then(res => {
            console.log("xxxxxxxxxxxget_old_data", res);
            if (res.data.supplier_id != null) {
                this.accountForm.supplier_id = res.data.supplier_id
                this.accountForm.company_name =res.data.company_name
                this.accountForm.legal_name =res.data.legal_name
                this.accountForm.phone =res.data.phone
                this.accountForm.id_card =res.data.id_card

                this.str2file_list(res.data.zm_photo , this.img_files.zm_photo  )
                this.str2file_list(res.data.fm_photo , this.img_files.fm_photo  )
                this.str2file_list(res.data.business_license , this.img_files.business_license  )
                this.str2file_list(res.data.letter_commitment , this.img_files.letter_commitment  )
                console.log("xxxxxxxxxxxgthis.img_files", this.img_files);
            }
        })
    },
    methods: {
        str2file_list(str , arr ){
            let imgs = str.split(",")
            for (let index = 0; index < imgs.length; index++) {
                const element = imgs[index];
                arr.push({ url: kits.img.url(element) })
            }
        },
        up_success_business_license(res, file, fileList) {
            this.up_success(res, file, fileList, "business_license")
        },
        up_success_zm_photo(res, file, fileList) {
            this.up_success(res, file, fileList, "zm_photo")
        },
        up_success_fm_photo(res, file, fileList) {
            this.up_success(res, file, fileList, "fm_photo")
        },

        up_success_letter_commitment(res, file, fileList) {
            this.up_success(res, file, fileList, "letter_commitment")
        },


        up_remove_business_license(file, fileList) {
            this.up_remove(file, fileList, "business_license")
        },
        up_remove_zm_photo(file, fileList) {
            this.up_remove(file, fileList, "zm_photo")
        },
        up_remove_fm_photo(file, fileList) {
            this.up_remove(file, fileList, "fm_photo")
        },


        up_remove_letter_commitment(file, fileList) {
            this.up_remove(file, fileList, "letter_commitment")
        },


        up_success(res, file, fileList, tag) {
            console.log("up_success res file, fileList", res, file, fileList, tag);
            if (res.status == 200) {
                this.$message.success('图片上传成功')
                this.img_files[tag].push(file)
                this.img_arr[tag].push(res.data.value)
            }
        },
        up_remove(file, fileList, tag) {
            console.log("up_remove file, fileList", file, fileList, tag);
            let index = kits.arr.getByIndex(this.img_files[tag], "name", file)
            if (index >= 0) {
                kits.arr.delElByIndex(this.img_files[tag], index)
                kits.arr.delElByIndex(this.img_arr[tag], index)
            }
            console.log("up_remove index", index, this.img_files[tag], this.accountForm[tag]);
        },

        onregister() {
            console.log("this.accountForm", this.accountForm, this.img_files)
            this.$refs.accountRuleForm.validate(valid => {
                if (valid) {
                    if (this.img_arr.zm_photo.length == 0 || this.img_arr.fm_photo.length == 0) {
                        this.$message.error("证书图片未上传完整!");
                        return false;
                    }
                    // if (this.img_arr.letter_commitment.length == 0  ) {
                    //     this.$message.error("承诺书图片未上传完整!");
                    //     return false;
                    // }
                    this.accountForm.business_license = this.img_arr.business_license.join(",")
                    this.accountForm.zm_photo = this.img_arr.zm_photo.join(",")
                    this.accountForm.fm_photo = this.img_arr.fm_photo.join(",")
                    this.accountForm.letter_commitment = this.img_arr.letter_commitment.join(",")
                    let rd = { consumer_name: this.$route.params.mobile,
                         mobile: this.$route.params.mobile,
                         is_supplier: 3,
                         consumer_password : this.$route.params.password,
                         info: this.accountForm }
                    register(rd).then(res => {
                        if (res.status == 200) {
                            this.$message.success("注册成功");
                            setuser_info(res.data)
                            this.applayStatusVisible = true
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
}
</script>

<style scoped>
/deep/ .el-dialog {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0px !important;
    border-radius: 10px;
}
.red{
    color: red !important;
}
.dow{
    color: blue;
    font-size: 16px;
}
</style>
